export function Contact() {
  return (
    <main>
      <header>
        <a href="/">Jeff Emmett</a>
      </header>
      <h1>Contact</h1>
      <p>
        Twitter: <a href="https://twitter.com/jeffemmett">@jeffemmett</a>
      </p>
      <p>
        BlueSky:{" "}
        <a href="https://bsky.app/profile/jeffemmett.bsky.social">
          @jeffemnmett.bsky.social
        </a>
      </p>
      <p>
        Mastodon:{" "}
        <a href="https://social.coop/@jeffemmett">@jeffemmett@social.coop</a>
      </p>
      <p>
        Email: <a href="mailto:jeffemmett@gmail.com">jeffemmett@gmail.com</a>
      </p>
      <p>
        GitHub: <a href="https://github.com/Jeff-Emmett">Jeff-Emmett</a>
      </p>
    </main>
  )
}
